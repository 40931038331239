const Logo = () => {
  return (
    <svg
      width='142'
      height='28'
      viewBox='0 0 142 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M43.9233 9.21049C45.2267 9.21049 46.4096 9.52741 47.472 10.1613C48.5433 10.7951 49.3958 11.6477 50.0297 12.719C50.6635 13.7813 50.9804 14.9642 50.9804 16.2676C50.9804 17.2407 50.7974 18.1557 50.4314 19.0128C50.0654 19.8609 49.5565 20.6108 48.9048 21.2625C48.262 21.9053 47.5121 22.4096 46.6551 22.7757C45.807 23.1417 44.8964 23.3247 43.9233 23.3247C42.986 23.3247 42.1646 23.1104 41.4594 22.6819C40.763 22.2445 40.1917 21.6865 39.7453 21.008V22.9765H36.96V2.88989H39.7453V11.5272C40.1917 10.8397 40.763 10.2818 41.4594 9.85326C42.1646 9.42475 42.986 9.21049 43.9233 9.21049ZM43.8965 20.6197C44.6732 20.6197 45.383 20.4233 46.0257 20.0305C46.6685 19.6377 47.1774 19.111 47.5523 18.4503C47.9362 17.7897 48.1281 17.0621 48.1281 16.2676C48.1281 15.4552 47.9362 14.7187 47.5523 14.0581C47.1684 13.3974 46.6551 12.8752 46.0123 12.4913C45.3696 12.0985 44.6643 11.9021 43.8965 11.9021C43.1199 11.9021 42.4369 12.0985 41.8477 12.4913C41.2585 12.8841 40.7987 13.4108 40.4684 14.0715C40.1381 14.7321 39.973 15.4641 39.973 16.2676C39.973 17.0711 40.1381 17.8031 40.4684 18.4637C40.7987 19.1244 41.2585 19.6511 41.8477 20.0439C42.4369 20.4278 43.1199 20.6197 43.8965 20.6197Z'
        fill='#F3F3F3'
      />
      <path
        d='M55.8955 27.9982H53.1101V9.58544H55.8955V11.5539C56.3418 10.8665 56.9132 10.3086 57.6095 9.88005C58.3059 9.45153 59.1272 9.23727 60.0735 9.23727C61.0466 9.23727 61.9572 9.42029 62.8053 9.78631C63.6623 10.1523 64.4122 10.6612 65.055 11.3129C65.6977 11.9557 66.2021 12.7011 66.5682 13.5492C66.9342 14.3973 67.1172 15.3079 67.1172 16.281C67.1172 17.2541 66.9342 18.1691 66.5682 19.0262C66.2021 19.8832 65.6977 20.6376 65.055 21.2893C64.4122 21.932 63.6623 22.4364 62.8053 22.8025C61.9572 23.1685 61.0466 23.3515 60.0735 23.3515C59.1272 23.3515 58.3059 23.1372 57.6095 22.7087C56.9132 22.2713 56.3418 21.7133 55.8955 21.0348V27.9982ZM60.0467 11.9289C59.27 11.9289 58.5871 12.1253 57.9979 12.5181C57.4086 12.9109 56.9489 13.4376 56.6186 14.0982C56.2883 14.7499 56.1231 15.4775 56.1231 16.281C56.1231 17.0845 56.2883 17.8165 56.6186 18.4771C56.9489 19.1378 57.4086 19.6645 57.9979 20.0573C58.5871 20.4501 59.27 20.6465 60.0467 20.6465C60.8145 20.6465 61.5197 20.4545 62.1625 20.0707C62.8053 19.6779 63.3186 19.1511 63.7025 18.4905C64.0863 17.8299 64.2783 17.0934 64.2783 16.281C64.2783 15.4865 64.0863 14.7589 63.7025 14.0982C63.3275 13.4376 62.8187 12.9109 62.1759 12.5181C61.5331 12.1253 60.8234 11.9289 60.0467 11.9289Z'
        fill='#F3F3F3'
      />
      <path
        d='M136.205 23.231C135.499 23.1863 134.812 23.0435 134.142 22.8025C133.473 22.5614 132.884 22.2356 132.375 21.8249C131.875 21.4053 131.5 20.9143 131.25 20.3519L133.62 19.3342C133.736 19.5752 133.937 19.8118 134.223 20.0439C134.517 20.276 134.861 20.4679 135.254 20.6197C135.647 20.7625 136.057 20.834 136.486 20.834C136.906 20.834 137.294 20.7759 137.651 20.6599C138.017 20.5349 138.316 20.3474 138.548 20.0975C138.78 19.8475 138.896 19.5484 138.896 19.2002C138.896 18.8074 138.762 18.5039 138.495 18.2897C138.236 18.0665 137.91 17.8968 137.517 17.7808C137.124 17.6647 136.731 17.5531 136.339 17.446C135.464 17.2496 134.66 16.9818 133.928 16.6425C133.205 16.2944 132.629 15.8525 132.201 15.3168C131.772 14.7723 131.558 14.1072 131.558 13.3216C131.558 12.4645 131.79 11.7236 132.254 11.0986C132.719 10.4648 133.326 9.97824 134.076 9.639C134.834 9.29976 135.642 9.13014 136.499 9.13014C137.571 9.13014 138.553 9.35779 139.445 9.81309C140.338 10.2684 141.021 10.8933 141.494 11.6878L139.285 13.0002C139.151 12.7323 138.95 12.4913 138.682 12.2771C138.414 12.0628 138.111 11.8932 137.771 11.7682C137.432 11.6343 137.08 11.5584 136.714 11.5405C136.258 11.5227 135.834 11.5762 135.441 11.7012C135.058 11.8173 134.75 12.0048 134.517 12.2637C134.285 12.5226 134.169 12.8529 134.169 13.2546C134.169 13.6474 134.308 13.942 134.584 14.1384C134.861 14.3259 135.209 14.4777 135.629 14.5937C136.057 14.7098 136.495 14.8392 136.941 14.9821C137.736 15.232 138.481 15.54 139.178 15.906C139.874 16.2631 140.436 16.705 140.865 17.2318C141.293 17.7585 141.499 18.4012 141.481 19.1601C141.481 19.9992 141.226 20.7358 140.718 21.3696C140.218 22.0035 139.566 22.4855 138.762 22.8158C137.959 23.1462 137.106 23.2845 136.205 23.231Z'
        fill='#F3F3F3'
      />
      <path
        d='M116.918 22.9766H114.146L109.66 9.58546H112.391L115.552 18.986L118.685 9.58546H121.497L124.631 18.986L127.778 9.58546H130.51L126.037 22.9766H123.238L120.078 13.5091L116.918 22.9766Z'
        fill='#F3F3F3'
      />
      <path
        d='M102.226 23.3247C100.994 23.3247 99.8687 23.0078 98.851 22.3739C97.8422 21.7401 97.0343 20.892 96.4272 19.8296C95.8291 18.7583 95.53 17.5755 95.53 16.281C95.53 15.299 95.7041 14.3839 96.0523 13.5358C96.4005 12.6788 96.8781 11.9289 97.4851 11.2861C98.1011 10.6344 98.8153 10.1256 99.6277 9.75953C100.44 9.39351 101.306 9.21049 102.226 9.21049C103.252 9.21049 104.194 9.42475 105.051 9.85327C105.908 10.2729 106.64 10.8576 107.247 11.6075C107.854 12.3485 108.301 13.2055 108.586 14.1786C108.872 15.1427 108.957 16.1694 108.841 17.2585H98.5162C98.6323 17.8745 98.8555 18.4325 99.1858 18.9324C99.525 19.4234 99.9536 19.8118 100.471 20.0975C100.998 20.3831 101.583 20.5304 102.226 20.5394C102.904 20.5394 103.52 20.3697 104.074 20.0305C104.636 19.6913 104.759 19.6 105.319 19.04H108.679C108.143 20.2273 106.935 21.441 105.855 22.1999C104.774 22.9498 103.565 23.3247 102.226 23.3247ZM98.4225 15.1294H106.029C105.939 14.4866 105.712 13.9063 105.346 13.3885C104.989 12.8618 104.538 12.4467 103.993 12.1431C103.458 11.8307 102.868 11.6745 102.226 11.6745C101.592 11.6745 101.003 11.8262 100.458 12.1298C99.9223 12.4333 99.4759 12.8484 99.1188 13.3751C98.7707 13.8929 98.5386 14.4777 98.4225 15.1294Z'
        fill='#F3F3F3'
      />
      <path
        d='M93.6721 14.5803V22.9765H90.8868V15.2633C90.8868 14.6651 90.7395 14.1206 90.4449 13.6296C90.1592 13.1386 89.7708 12.7502 89.2798 12.4645C88.7977 12.1699 88.2532 12.0226 87.6461 12.0226C87.0569 12.0226 86.5168 12.1699 86.0258 12.4645C85.5348 12.7502 85.142 13.1386 84.8474 13.6296C84.5528 14.1206 84.4055 14.6651 84.4055 15.2633V22.9765H81.6201V9.58545H84.4055V11.0049C84.8697 10.4514 85.441 10.014 86.1195 9.69257C86.798 9.37119 87.5301 9.21049 88.3157 9.21049C89.3066 9.21049 90.2083 9.45153 91.0207 9.93361C91.8331 10.4157 92.4758 11.0629 92.949 11.8753C93.4311 12.6877 93.6721 13.5894 93.6721 14.5803Z'
        fill='#F3F3F3'
      />
      <circle cx='74.3701' cy='16.25' r='2.25' fill='#F3F3F3' />
      <g clipPath='url(#clip0_4325_24095)'>
        <path
          d='M13.5 0.5C6.04512 0.5 0 6.54443 0 13.9996C0 21.4549 6.04512 27.5 13.5 27.5C20.9557 27.5 27 21.4557 27 13.9996C27 6.54359 20.9557 0.5 13.5 0.5ZM16.7633 21.4502C12.8955 21.4502 9.7594 18.3141 9.7594 14.446C9.7594 10.577 12.8955 7.44198 16.7633 7.44198C20.6323 7.44198 23.7674 10.5781 23.7674 14.446C23.7683 18.3141 20.6323 21.4502 16.7633 21.4502Z'
          fill='#F3F3F3'
        />
        <path
          d='M20.327 17.0099C18.8143 17.0099 17.588 15.7836 17.588 14.2706C17.588 12.7577 18.8143 11.5314 20.327 11.5314C20.4355 11.5314 20.5385 11.5511 20.6444 11.5629C19.8266 10.5418 18.5838 9.87489 17.1741 9.87489C14.7094 9.87489 12.7113 11.873 12.7113 14.3375C12.7113 16.803 14.7094 18.8011 17.1741 18.8011C18.6535 18.8011 19.954 18.0737 20.7664 16.9647C20.6227 16.9891 20.478 17.0099 20.327 17.0099Z'
          fill='#F3F3F3'
        />
      </g>
      <defs>
        <clipPath id='clip0_4325_24095'>
          <rect
            width='27'
            height='27'
            fill='white'
            transform='translate(0 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
