import { FC } from 'react';
import { deleteCookie } from '~/utils/helpers';

import styles from './PwaInstructionsModal.module.scss';

interface Props {
  onClose: () => void;
}
const PwaInstructionsAndroid: FC<Props> = ({ onClose }) => {
  return (
    <div className={styles.instructions}>
      <div className={styles.modalTop}>
        <div className={styles.description}>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M2.75 12.0001C2.75 5.06309 5.063 2.75009 12 2.75009C18.937 2.75009 21.25 5.06309 21.25 12.0001C21.25 18.9371 18.937 21.2501 12 21.2501C5.063 21.2501 2.75 18.9371 2.75 12.0001Z'
              stroke='#3478F6'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M11.9955 15.5H12.0045'
              stroke='#3478F6'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M12 8.10498V12'
              stroke='#3478F6'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          A pop-up window will appear to guide you through the process of adding
          the app to your home screen
        </div>
      </div>
      <div className={styles.modalBottom}>
        <button
          onClick={() => {
            deleteCookie('pwa_modal');
            onClose();
          }}
          className={styles.gotItButton}
        >
          Got it
        </button>
      </div>
    </div>
  );
};

export default PwaInstructionsAndroid;
