const SearchChart = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.7638 11.4985V14.1292'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.23645 8.55078V14.1289'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.0314 5.88086V14.1291'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.91699 10.0305C1.91699 3.94545 3.94594 1.9165 10.031 1.9165C16.1161 1.9165 18.1451 3.94545 18.1451 10.0305C18.1451 16.1156 16.1161 18.1446 10.031 18.1446C3.94594 18.1446 1.91699 16.1156 1.91699 10.0305Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SearchChart;
