const Plus = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.7507 4.75C12.7507 4.33579 12.4149 4 12.0007 4C11.5865 4 11.2507 4.33579 11.2507 4.75V11.2506H4.75C4.33579 11.2506 4 11.5863 4 12.0006C4 12.4148 4.33579 12.7506 4.75 12.7506H11.2507V19.2514C11.2507 19.6657 11.5865 20.0014 12.0007 20.0014C12.4149 20.0014 12.7507 19.6657 12.7507 19.2514V12.7506H19.2514C19.6657 12.7506 20.0014 12.4148 20.0014 12.0006C20.0014 11.5863 19.6657 11.2506 19.2514 11.2506H12.7507V4.75Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default Plus;
