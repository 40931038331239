const EnvelopeBig = () => {
  return (
    <svg
      width='191'
      height='133'
      viewBox='0 0 191 133'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_5015_6720)'>
        <path
          d='M89.3334 13.9382L42 52.0282V113.373C42 119.736 47.158 124.894 53.5207 124.894H139.275C145.638 124.894 150.796 119.736 150.796 113.373V52.0282L103.808 13.962C99.5912 10.5456 93.5617 10.5357 89.3334 13.9382Z'
          fill='#35383F'
        />
        <rect
          x='57.6992'
          y='25.1577'
          width='77.7634'
          height='96.8031'
          rx='13.8249'
          fill='#ED7135'
        />
        <path
          d='M64.3906 39.7529C64.3906 38.6432 65.2902 37.7437 66.3999 37.7437H124.898C126.007 37.7437 126.907 38.6432 126.907 39.7529C126.907 40.8626 126.007 41.7621 124.898 41.7621H66.3999C65.2902 41.7621 64.3906 40.8626 64.3906 39.7529Z'
          fill='#EAECEF'
        />
        <path
          opacity='0.5'
          d='M64.3906 52.8125C64.3906 51.7028 65.2902 50.8032 66.3999 50.8032H124.898C126.007 50.8032 126.907 51.7028 126.907 52.8125C126.907 53.9221 126.007 54.8217 124.898 54.8217H66.3999C65.2902 54.8217 64.3906 53.9221 64.3906 52.8125Z'
          fill='#EAECEF'
        />
        <path
          opacity='0.5'
          d='M64.3906 65.8725C64.3906 64.7628 65.2902 63.8633 66.3999 63.8633H124.898C126.007 63.8633 126.907 64.7628 126.907 65.8725C126.907 66.9822 126.007 67.8818 124.898 67.8818H66.3999C65.2902 67.8818 64.3906 66.9822 64.3906 65.8725Z'
          fill='#EAECEF'
        />
        <path
          opacity='0.5'
          d='M64.3906 78.9331C64.3906 77.8234 65.2902 76.9238 66.3999 76.9238H124.898C126.007 76.9238 126.907 77.8234 126.907 78.9331C126.907 80.0427 126.007 80.9423 124.898 80.9423H66.3999C65.2902 80.9423 64.3906 80.0427 64.3906 78.9331Z'
          fill='#EAECEF'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M42 51.9248V113.373C42 119.736 47.158 124.894 53.5207 124.894H108.255C119.465 124.894 124.072 110.509 114.948 103.996L42 51.9248Z'
          fill='url(#paint0_linear_5015_6720)'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M150.797 51.9248V113.373C150.797 119.736 145.639 124.894 139.276 124.894H51.3874L150.797 51.9248Z'
          fill='url(#paint1_linear_5015_6720)'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M151 52.8704L55.1358 124.894H51.1348L149.821 51.2471L151 52.8704Z'
          fill='#161718'
        />
        <path d='M0 0H191V133H0V0Z' fill='url(#paint2_linear_5015_6720)' />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_5015_6720'
          x1='42'
          y1='51.8077'
          x2='116.844'
          y2='135.693'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E7E7E7' />
          <stop offset='1' stopColor='#A0A0A0' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_5015_6720'
          x1='150.797'
          y1='51.8077'
          x2='75.7439'
          y2='133.611'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E7E7E7' />
          <stop offset='1' stopColor='#A0A0A0' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_5015_6720'
          x1='95.5'
          y1='0'
          x2='95.5'
          y2='133'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#161718' stopOpacity='0' />
          <stop offset='1' stopColor='#161718' stopOpacity='0.9' />
        </linearGradient>
        <clipPath id='clip0_5015_6720'>
          <rect width='191' height='133' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EnvelopeBig;
