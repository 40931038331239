const PwaPlus = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='2.65'
        y='2.65'
        width='18.7'
        height='18.7'
        rx='2.77857'
        stroke='currentColor'
        strokeWidth='1.3'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.8 7.65C12.8 7.29101 12.509 7 12.15 7C11.791 7 11.5 7.29101 11.5 7.65V11.2H7.65C7.29101 11.2 7 11.491 7 11.85C7 12.209 7.29101 12.5 7.65 12.5L11.5 12.5V16.35C11.5 16.709 11.791 17 12.15 17C12.509 17 12.8 16.709 12.8 16.35V12.5L16.35 12.5C16.709 12.5 17 12.209 17 11.85C17 11.491 16.709 11.2 16.35 11.2H12.8V7.65Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default PwaPlus;
