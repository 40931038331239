import { FC } from 'react';
import SearchChart from '~/icons/SearchChart';
import { MinimalTagResource } from '~/types';
import { getFeedType } from '~/utils/helpers';

import { NavLink, useLocation } from '@remix-run/react';

import styles from './SearchDropdownTopics.module.scss';

interface Props {
  title: string;
  linkToAll?: string;
  topics: MinimalTagResource[];
}

const SearchDropdownTopics: FC<Props> = ({ topics, linkToAll, title }) => {
  const location = useLocation();

  const feedType = getFeedType(location.pathname);

  return (
    <div className={styles.box}>
      <div className={styles.heading}>
        <SearchChart />
        {title}
        {linkToAll && (
          <NavLink className={styles.linkToAll} to={linkToAll}>
            View All
          </NavLink>
        )}
      </div>
      <div className={styles.topics}>
        {topics.map((topic) => (
          <NavLink
            key={topic.id}
            className={styles.topic}
            to={`${feedType}/${topic.slug}`}
          >
            {topic.title}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default SearchDropdownTopics;
