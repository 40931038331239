import { FC } from 'react';
import Search from '~/icons/Search';
import { MinimalTagResource } from '~/types';
import { getFeedType } from '~/utils/helpers';

import { NavLink, useLocation } from '@remix-run/react';

import SearchDropdownTopics from '../SearchDropdownTopics/SearchDropdownTopics';
import styles from './SearchDropdown.module.scss';

interface Props {
  topTags?: MinimalTagResource[];
  hotTags?: MinimalTagResource[];
  foundTopics?: MinimalTagResource[];
  query?: string;
}

const SearchDropdown: FC<Props> = ({
  query,
  foundTopics,
  topTags,
  hotTags,
}) => {
  const location = useLocation();

  const feedType = getFeedType(location.pathname);

  const renderSearchPageSlug = () => {
    switch (feedType) {
      case 'auto-demo':
        return '/auto-demo-search';
      case 'auto-demo-2':
        return '/auto-demo-2-search';
      case 'discover':
        return '/search';
      default:
        return '/search';
    }
  };

  return (
    <div
      className={styles.box}
      role='menu'
      tabIndex={-1}
      onMouseDown={(e) => e.preventDefault()}
    >
      {query && foundTopics?.length ? (
        <SearchDropdownTopics title='Topics' topics={foundTopics} />
      ) : (
        <>
          {!!hotTags?.length && (
            <SearchDropdownTopics title='hot topics' topics={hotTags} />
          )}
          {!!topTags?.length && (
            <SearchDropdownTopics
              title='discover · top 5'
              topics={topTags}
              linkToAll='/discover'
            />
          )}
        </>
      )}
      {query && (
        <NavLink
          to={`${renderSearchPageSlug()}?query=${query}`}
          className={styles.dropdownBottom}
        >
          <Search /> Search for &quot;{query}&quot;
        </NavLink>
      )}
    </div>
  );
};

export default SearchDropdown;
