import styles from './NotificationsCenter.module.scss';

const Notifications = () => {
  return (
    <div className={styles.box}>
      <div className={styles.textPlaceholder}>You&apos;re all caught up!</div>
    </div>
  );
};

export default Notifications;
