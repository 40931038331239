import { FC } from 'react';
import LogoutIcon from '~/icons/LogoutIcon';
import Profile from '~/icons/Profile';
import SettingsIcon from '~/icons/SettingsIcon';

import { NavLink } from '@remix-run/react';

import styles from './HeaderUser.module.scss';

interface Props {
  onLogout: () => Promise<void>;
}

const UserDropdown: FC<Props> = ({ onLogout }) => {
  return (
    <div className={styles.dropdown}>
      <NavLink className={styles.dropdownButton} to='/user-profile'>
        <Profile />
        View Profile
      </NavLink>
      <NavLink className={styles.dropdownButton} to='/user-settings'>
        <SettingsIcon />
        Settings
      </NavLink>
      <div className={styles.dropdownBottom}>
        <button className={styles.dropdownButton} onClick={onLogout}>
          <LogoutIcon />
          Log out
        </button>
      </div>
    </div>
  );
};

export default UserDropdown;
