import { motion } from 'framer-motion';
import { FC, useEffect } from 'react';
import { ToastType, useToasts } from '~/context/ToastContext';
import SuccessIcon from '~/icons/SuccessIcon';

import styles from './Toast.module.scss';

interface ToastProps {
  toast: ToastType;
}

const Toast: FC<ToastProps> = ({ toast }) => {
  const { closeToast } = useToasts();

  useEffect(() => {
    if (toast.status !== 'loading') {
      const timer = setTimeout(() => {
        closeToast(toast.id);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [toast.id, toast.status, closeToast]);

  const renderTitle = () => {
    if (toast.message) {
      return toast.message;
    }

    switch (toast.status) {
      case 'success':
        return 'Done';
      case 'error':
        return 'Failed';
      case 'loading':
        return 'Loading...';
      default:
        return null;
    }
  };

  return (
    <motion.div
      className={styles.box}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      transition={{ duration: 0.2, ease: 'easeOut' }}
    >
      <div className={styles.iconHolder}>
        <SuccessIcon />
      </div>
      <p className={styles.text}>{renderTitle()}</p>
    </motion.div>
  );
};

export default Toast;
