import { motion } from 'framer-motion';
import { FC, useEffect, useRef } from 'react';
import NewsletterForm from '~/components/NewsletterForm/NewsletterForm';
import EnvelopeBig from '~/icons/EnvelopeBig';

import { useLocation } from '@remix-run/react';

import styles from './NewsletterModal.module.scss';

interface Props {
  onClose: () => void;
}

const NewsletterModal: FC<Props> = ({ onClose }) => {
  const location = useLocation();
  const initialPath = useRef(location.pathname);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    if (location.pathname !== initialPath.current) {
      onClose();
    }
  }, [location.pathname, onClose]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className={styles.modalOverlay}
    >
      <div className={styles.modal}>
        <div className={styles.headRow}>
          <div>
            <h5 className={styles.heading}>
              Stay Informed - Subscribe Newsletter
            </h5>
            <div className={styles.description}>
              <p>
                Receive exclusive insights, updates, and stories delivered
                straight to your inbox every week!
              </p>
            </div>
          </div>
          <EnvelopeBig />
        </div>
        <NewsletterForm inModal onClose={onClose} />
      </div>
    </motion.div>
  );
};

export default NewsletterModal;
