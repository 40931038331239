const PwaCenterNotifsIcon = () => {
  return (
    <svg
      width='72'
      height='73'
      viewBox='0 0 72 73'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M36 72.5C55.8822 72.5 72 56.3822 72 36.5C72 16.6178 55.8822 0.5 36 0.5C16.1177 0.5 0 16.6178 0 36.5C0 56.3822 16.1177 72.5 36 72.5Z'
        fill='#35383F'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M51.388 18.2529C52.042 16.3491 52.1779 14.4716 52.4117 12.5022C52.4573 12.1176 52.1821 11.7699 51.7975 11.7238C51.4127 11.6778 51.0635 11.9534 51.0177 12.338C50.7961 14.2055 50.6811 15.9911 50.0603 17.7996C49.9346 18.1653 50.1303 18.5645 50.4969 18.6885C50.863 18.8149 51.2626 18.6187 51.388 18.2529Z'
        fill='#5E626A'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M55.1314 22.3896C58.654 19.7116 62.0643 16.1665 64.5539 12.5018C64.771 12.1816 64.6871 11.7456 64.3663 11.5297C64.0456 11.3137 63.6089 11.3965 63.3917 11.7166C60.9844 15.2599 57.6862 18.6886 54.2801 21.2763C53.9722 21.5111 53.913 21.9507 54.1479 22.2574C54.3829 22.564 54.8235 22.6244 55.1314 22.3896Z'
        fill='#5E626A'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M56.0075 26.8674C58.9433 27.6235 62.0889 27.0613 64.9037 26.1207C65.271 25.9966 65.4689 25.5999 65.3458 25.2353C65.2226 24.8684 64.8243 24.6712 64.4571 24.793C61.8936 25.6507 59.0317 26.2007 56.3579 25.5125C55.9828 25.4158 55.5998 25.6415 55.5034 26.0146C55.4066 26.3876 55.6327 26.7707 56.0075 26.8674Z'
        fill='#5E626A'
      />
      <path
        d='M19.0824 47.5135L31.0328 14.6985L56.0948 41.1978L26.8119 54.6402C22.2139 56.7509 17.3512 52.2674 19.0824 47.5135Z'
        fill='#ED7135'
      />
      <ellipse
        cx='43.8443'
        cy='27.7846'
        rx='4.18272'
        ry='18.3333'
        transform='rotate(-43.1847 43.8443 27.7846)'
        fill='url(#paint0_linear_5336_10469)'
      />
      <mask
        id='mask0_5336_10469'
        maskUnits='userSpaceOnUse'
        x='18'
        y='14'
        width='39'
        height='42'
      >
        <path
          d='M19.0824 47.5135L31.0328 14.6985L56.0948 41.1978L26.8119 54.6402C22.2139 56.7509 17.3512 52.2674 19.0824 47.5135Z'
          fill='#E1DCEB'
        />
      </mask>
      <g mask='url(#mask0_5336_10469)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M31.0145 35.1408C25.9933 28.5651 23.4527 20.7255 22.6333 15.4623L26.6766 14.8328C27.4142 19.5704 29.7423 26.7321 34.2668 32.6574C38.7488 38.527 45.306 43.1041 54.6576 43.1041V47.1961C43.7439 47.1961 36.0783 41.7724 31.0145 35.1408Z'
          fill='url(#paint1_linear_5336_10469)'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M24.5473 44.6627C19.526 38.0869 16.9854 30.2473 16.166 24.9841L20.2093 24.3546C20.9469 29.0922 23.275 36.2539 27.7995 42.1792C32.2815 48.0488 38.8387 52.6259 48.1903 52.6259V56.7179C37.2766 56.7179 29.6111 51.2942 24.5473 44.6627Z'
          fill='url(#paint2_linear_5336_10469)'
        />
      </g>
      <circle cx='46' cy='50' r='8' fill='#42BE77' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M49.2683 47.7242C49.581 48.0196 49.5766 48.4945 49.2585 48.7849L45.424 52.2849C45.1097 52.5717 44.6059 52.5717 44.2916 52.2849L42.7415 50.87C42.4234 50.5796 42.419 50.1047 42.7317 49.8093C43.0444 49.514 43.5558 49.5099 43.8739 49.8002L44.8578 50.6983L48.1261 47.7151C48.4442 47.4248 48.9556 47.4288 49.2683 47.7242Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_5336_10469'
          x1='43.8443'
          y1='9.45126'
          x2='43.8443'
          y2='46.1178'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#161718' />
          <stop offset='1' stopColor='#2B2C2D' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_5336_10469'
          x1='22.6333'
          y1='14.7808'
          x2='55.153'
          y2='40.5255'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E7E7E7' />
          <stop offset='1' stopColor='#A0A0A0' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_5336_10469'
          x1='16.166'
          y1='24.3026'
          x2='48.6857'
          y2='50.0473'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E7E7E7' />
          <stop offset='1' stopColor='#A0A0A0' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PwaCenterNotifsIcon;
