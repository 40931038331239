import { useCallback, useEffect, useState } from 'react';

export const useInstallPrompt = (
  onShowInstructions: () => void,
  onClose?: () => void,
) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const handleInstallClick = useCallback(() => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      setDeferredPrompt(null);
    } else {
      onShowInstructions();
      onClose?.();
    }
  }, [deferredPrompt, onShowInstructions, onClose]);
  useEffect(() => {
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt,
      );
    };
  }, []);

  return { handleInstallClick };
};
