import { motion } from 'framer-motion';
import { FC } from 'react';

import styles from './Overlay.module.scss';

interface Props {
  onClick?: () => void;
}

const Overlay: FC<Props> = ({ onClick }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className={styles.overlay}
      onClick={onClick}
    />
  );
};

export default Overlay;
