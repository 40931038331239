const Search = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2753 3.46429C6.96095 3.46429 3.46436 6.96089 3.46436 11.2752C3.46436 15.5896 6.96095 19.0862 11.2753 19.0862C15.5888 19.0862 19.0863 15.5896 19.0863 11.2752C19.0863 6.96094 15.5888 3.46429 11.2753 3.46429ZM1.96436 11.2752C1.96436 6.13246 6.13252 1.96429 11.2753 1.96429C16.4171 1.96429 20.5863 6.13241 20.5863 11.2752C20.5863 16.4181 16.4171 20.5862 11.2753 20.5862C6.13252 20.5862 1.96436 16.418 1.96436 11.2752Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8987 19.2376C19.5336 19.2376 19.2373 19.5345 19.2373 19.8981C19.2373 20.2635 19.5336 20.5595 19.8987 20.5595C20.2632 20.5595 20.5592 20.2642 20.5592 19.8981C20.5592 19.5339 20.2632 19.2376 19.8987 19.2376ZM17.7373 19.8981C17.7373 18.7054 18.7058 17.7376 19.8987 17.7376C21.0923 17.7376 22.0592 18.7061 22.0592 19.8981C22.0592 21.092 21.0923 22.0595 19.8987 22.0595C18.7058 22.0595 17.7373 21.0926 17.7373 19.8981Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Search;
