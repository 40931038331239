import { FC } from 'react';

import styles from './NotificationsCenter.module.scss';

interface Props {
  onInstall: () => void;
}

const DownloadPwa: FC<Props> = ({ onInstall }) => {
  return (
    <div className={styles.pwaBox}>
      <div className={styles.topRow}>
        <div className={styles.icon}>
          <img src='/favicon/icon-72.png' alt='bp news icon' />
        </div>
        <div>
          <div className={styles.pwaTitle}>BP News</div>
          <div className={styles.pwaDescription}>
            Easiest Access to Your Daily News
          </div>
        </div>
      </div>
      <button onClick={onInstall} className={styles.downloadButton}>
        Add
      </button>
    </div>
  );
};

export default DownloadPwa;
