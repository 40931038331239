const IosPwa = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.0075 15.382C12.3682 15.382 12.6689 15.0845 12.6689 14.7261V4.30953L12.6163 2.86828L13.4053 3.69185L14.9836 5.37711C15.1039 5.51437 15.2767 5.58301 15.4346 5.58301C15.7803 5.58301 16.0433 5.33137 16.0433 4.98821C16.0433 4.81282 15.9681 4.67556 15.8479 4.54592L12.4885 1.22876C12.3231 1.061 12.1728 1 12.0075 1C11.8422 1 11.6919 1.061 11.5341 1.22876L8.1671 4.54592C8.03934 4.67556 7.9717 4.81282 7.9717 4.98821C7.9717 5.33137 8.21971 5.58301 8.56542 5.58301C8.72325 5.58301 8.90362 5.51437 9.02386 5.37711L10.6096 3.69185L11.3987 2.86065L11.3461 4.30953V14.7261C11.3461 15.0845 11.6543 15.382 12.0075 15.382ZM6.43103 23H17.5765C19.523 23 20.5 22.0087 20.5 20.0641V10.2881C20.5 8.35111 19.523 7.35215 17.5765 7.35215H14.8408V8.70189H17.5539C18.5836 8.70189 19.1697 9.26619 19.1697 10.3567V19.9954C19.1697 21.086 18.5836 21.6502 17.5539 21.6502H6.45357C5.40892 21.6502 4.83774 21.086 4.83774 19.9954V10.3567C4.83774 9.26619 5.40892 8.70189 6.45357 8.70189H9.18169V7.35215H6.43103C4.49204 7.35215 3.5 8.33586 3.5 10.2881V20.0641C3.5 22.0163 4.49204 23 6.43103 23Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default IosPwa;
