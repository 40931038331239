import { AnimatePresence } from 'framer-motion';
import { useToasts } from '~/context/ToastContext';

import styles from './ToastList.module.scss';
import Toast from './Toatst';

const ToastList = () => {
  const { toasts } = useToasts();

  return (
    <div className={styles.box}>
      <AnimatePresence>
        {toasts.map((toast) => (
          <Toast key={toast.id} toast={toast} />
        ))}
      </AnimatePresence>
    </div>
  );
};

export default ToastList;
