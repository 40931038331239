const LogoutIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.791 12.1207H9.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.8652 9.2047L21.7932 12.1207L18.8652 15.0367'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.3613 7.62989C16.0313 4.04989 14.6913 2.74989 9.36133 2.74989C2.26033 2.74989 2.26033 5.05989 2.26033 11.9999C2.26033 18.9399 2.26033 21.2499 9.36133 21.2499C14.6913 21.2499 16.0313 19.9499 16.3613 16.3699'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default LogoutIcon;
