import { useEffect } from 'react';

import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from 'vanilla-cookieconsent';

const useCookieConsent = () => {
  useEffect(() => {
    // eslint-disable-next-line import/no-named-as-default-member
    CookieConsent.run({
      guiOptions: {
        consentModal: {
          layout: "box",
          position: "bottom left",
          equalWeightButtons: true,
          flipButtons: false
        },
        preferencesModal: {
          layout: "box",
          position: "right",
          equalWeightButtons: true,
          flipButtons: false
        }
      },
      categories: {
        necessary: {
          readOnly: true
        },
        functionality: {},
        analytics: {},
        marketing: {}
      },
      language: {
        default: "en",
        autoDetect: "browser",
        translations: {
          en: {
            consentModal: {
              title: "We value your privacy.",
              description: "We and our partners store or access information on devices, such as cookies and process personal data, such as unique identifiers and standard information sent by a device.",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              showPreferencesBtn: "Manage preferences",
              footer: "<a href=\"/privacy-policy\">Privacy Policy</a>\n<a href=\"/terms-and-conditions\">Terms and conditions</a>"
            },
            preferencesModal: {
              title: "Consent Preferences Center",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              savePreferencesBtn: "Save preferences",
              closeIconLabel: "Close modal",
              serviceCounterLabel: "Service|Services",
              sections: [
                {
                  title: "Cookie Usage",
                  description: "We use cookies. Check them out here:"
                },
                {
                  title: "Strictly Necessary Cookies <span class=\"pm__badge\">Always Enabled</span>",
                  description: "List of necessary cookies",
                  linkedCategory: "necessary"
                },
                {
                  title: "Functionality Cookies",
                  description: "reader_welcome_message - cookie responsible for displaying the welcome message",
                  linkedCategory: "functionality"
                },
                {
                  title: "Analytics Cookies",
                  description: "List of analytics cookies",
                  linkedCategory: "analytics"
                },
                {
                  title: "Advertisement Cookies",
                  description: "List of advertisement cookies",
                  linkedCategory: "marketing"
                },
                {
                  title: "More information",
                  description: "For any query in relation to my policy on cookies and your choices, please contact the administrator."
                }
              ]
            }
          }
        }
      }
    })
  }, []);
}

export default useCookieConsent;