const NotificationsIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.306 20.5122C13.0117 21.9579 10.9927 21.9751 9.68604 20.5122'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.9964 2.51416C7.5621 2.51416 5.63543 6.5294 5.63543 9.18368C5.63543 11.1675 5.92305 10.5837 4.82496 13.0037C3.484 16.4523 8.87638 17.8618 11.9964 17.8618C15.1154 17.8618 20.5078 16.4523 19.1678 13.0037C18.0697 10.5837 18.3573 11.1675 18.3573 9.18368C18.3573 6.5294 16.4297 2.51416 11.9964 2.51416Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default NotificationsIcon;
