import { FC } from 'react';
import IosPwa from '~/icons/IosPwa';
import PwaPlus from '~/icons/PwaPlus';

import styles from './PwaInstructionsModal.module.scss';

interface Props {
  onClose: () => void;
}
const PwaInstructions: FC<Props> = ({ onClose }) => {
  return (
    <div className={styles.instructions}>
      <div className={styles.modalTop}>
        <div className={styles.list}>
          <div className={styles.listItem}>
            <div className={styles.badge}>1</div>
            <div className={styles.text}>
              Tap on <IosPwa />
            </div>
          </div>
          <div className={styles.listItem}>
            <div className={styles.badge}>2</div>
            <div className={styles.text}>
              Select <strong>Add to Home Screen</strong>
              <PwaPlus />
            </div>
          </div>
          <div className={styles.listItem}>
            <div className={styles.badge}>3</div>
            <div className={styles.text}>Ready to use!</div>
          </div>
        </div>
      </div>
      <div className={styles.modalBottom}>
        <button
          onClick={() => {
            onClose();
          }}
          className={styles.gotItButton}
        >
          Got it
        </button>
      </div>
    </div>
  );
};

export default PwaInstructions;
