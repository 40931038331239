const Discover = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2111 11.2119L10.1641 14.5589L13.5101 13.5109L14.5581 10.1639L11.2111 11.2119ZM9.02008 16.4519C8.82408 16.4519 8.63208 16.3749 8.48908 16.2329C8.29208 16.0349 8.22008 15.7439 8.30408 15.4789L9.89708 10.3899C9.97008 10.1539 10.1541 9.9709 10.3881 9.8979L15.4771 8.3049C15.7441 8.2199 16.0341 8.2929 16.2321 8.4899C16.4291 8.6879 16.5011 8.9789 16.4171 9.2439L14.8251 14.3329C14.7521 14.5679 14.5671 14.7519 14.3331 14.8249L9.24408 16.4179C9.17008 16.4409 9.09408 16.4519 9.02008 16.4519Z"
        fill="currentColor"
      />
      <mask
        id="mask0_3960_6897"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="21"
        height="21"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 2H22.7218V22.7217H2V2Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3960_6897)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.361 3.5C7.475 3.5 3.5 7.476 3.5 12.361C3.5 17.247 7.475 21.222 12.361 21.222C17.247 21.222 21.222 17.247 21.222 12.361C21.222 7.476 17.247 3.5 12.361 3.5ZM12.361 22.722C6.648 22.722 2 18.074 2 12.361C2 6.648 6.648 2 12.361 2C18.074 2 22.722 6.648 22.722 12.361C22.722 18.074 18.074 22.722 12.361 22.722Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default Discover;
