import { motion } from 'framer-motion';
import { FC } from 'react';

import { isIOS } from '../../utils/helpers';
import PwaInstructions from './PwaInstructions';
import PwaInstructionsAndroid from './PwaInstructionsAndroid';
import styles from './PwaInstructionsModal.module.scss';

interface Props {
  onClose: () => void;
}

const PwaInstructionsModal: FC<Props> = ({ onClose }) => {
  const ios = isIOS();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className={styles.modalOverlay}
    >
      <div className={styles.modal}>
        {ios ? (
          <PwaInstructions onClose={onClose} />
        ) : (
          <PwaInstructionsAndroid onClose={onClose} />
        )}
      </div>
    </motion.div>
  );
};

export default PwaInstructionsModal;
