const Trending = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.25 11.9999C21.25 5.06291 18.937 2.74991 12 2.74991C5.063 2.74991 2.75 5.06291 2.75 11.9999C2.75 18.9369 5.063 21.2499 12 21.2499C18.937 21.2499 21.25 18.9369 21.25 11.9999Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 7.91403L12 16.086"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.24805 11.6782C8.24805 11.6782 10.776 7.91419 12 7.91419C13.224 7.91419 15.748 11.6782 15.748 11.6782"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Trending;
