import { FC } from 'react';
import PwaCenterNotifsIcon from '~/icons/PwaCenterNotifsIcon';

import styles from './NotificationsCenter.module.scss';

interface Props {
  handleEnableNotifications(): Promise<void>;
}

const EnableNotifications: FC<Props> = ({ handleEnableNotifications }) => {
  return (
    <div className={styles.notifsBox}>
      <div className={styles.topRowNotifs}>
        <div className={styles.iconNotifs}>
          <PwaCenterNotifsIcon />
        </div>
        <div>
          <div className={styles.pwaTitle}>Enable Notifications</div>
          <div className={styles.pwaDescription}>
            Get updated on Top News posts twice per day
          </div>
        </div>
      </div>
      <div className={styles.bottomNotifs}>
        <button
          className={styles.downloadButton}
          onClick={handleEnableNotifications}
        >
          Enable
        </button>
      </div>
    </div>
  );
};

export default EnableNotifications;
