import classNames from 'classnames';
import { motion } from 'framer-motion';
import { FC, useEffect, useRef, useState } from 'react';
import { useToasts } from '~/context/ToastContext';
import formStyles from '~/scss/layout/form.module.scss';
import { Tag } from '~/types';
import { areArraysEqual, setCookie } from '~/utils/helpers';

import { useFetcher } from '@remix-run/react';

import Spinner from '../Spinner/Spinner';
import TagButton from '../Tag/TagButton/TagButton';
import styles from './TopicsModal.module.scss';

interface ActionData {
  status: number;
}

interface Props {
  hotTags: Tag[];
  mainTags: Tag[];
  onClose: () => void;
}

const TopicsModal: FC<Props> = ({ hotTags, mainTags, onClose }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const hotTagsRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const { createToast } = useToasts();

  const fetcher = useFetcher<ActionData>();

  const isSubmitting = fetcher.state === 'submitting';

  const getFavoriteTagIds = (tags: Tag[]) =>
    tags.filter((tag) => tag.is_favorite_by_current_user).map((tag) => tag.id);

  const favoriteTagsIds = [
    ...getFavoriteTagIds(mainTags),
    ...getFavoriteTagIds(hotTags ?? []),
  ];

  const [currectFavoriteTagsIds, setCurrectFavoriteTagsIds] =
    useState<number[]>(favoriteTagsIds);

  const isDataClean = areArraysEqual(currectFavoriteTagsIds, favoriteTagsIds);

  useEffect(() => {
    if (fetcher.data?.status === 200 && fetcher.state === 'idle') {
      createToast('success', 'Topics changes have been saved successfully');
      onClose();
    }
  }, [fetcher.data, fetcher.state, createToast, onClose]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className={styles.modalOverlay}
    >
      <div ref={modalRef} className={styles.modal}>
        <div ref={headerRef} className={classNames(styles.header)}>
          <h3 className={styles.heading}>Personalized Content Feed</h3>
          <div className={styles.description}>
            <p>
              Choose at least one topic to prioritize in your news feed and stay
              updated. You can easily modify your selections in the menu or your
              profile at any time.
            </p>
          </div>
        </div>
        <div className={styles.contentWrap}>
          <div className={styles.content}>
            <div className={styles.tagsBox}>
              <h2 className={styles.tagsHeading}>Main Topics</h2>
              <div className={styles.tagsLayout}>
                {mainTags.map((tag) => (
                  <TagButton
                    key={tag.id}
                    tag={tag}
                    favoriteTagsIds={currectFavoriteTagsIds}
                    setFavoriteTagsIds={setCurrectFavoriteTagsIds}
                  />
                ))}
              </div>
            </div>
            {!!hotTags?.length && (
              <div ref={hotTagsRef} className={styles.tagsBox}>
                <h2 className={styles.tagsHeading}>Hot Topics</h2>
                <div className={styles.tagsLayout}>
                  {hotTags.map((tag) => (
                    <TagButton
                      key={tag.id}
                      tag={tag}
                      favoriteTagsIds={currectFavoriteTagsIds}
                      setFavoriteTagsIds={setCurrectFavoriteTagsIds}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div ref={footerRef} className={styles.footer}>
          <fetcher.Form
            method='post'
            action='/save-tags'
            className={styles.form}
          >
            <input
              type='text'
              name='tag_ids'
              hidden
              value={currectFavoriteTagsIds.join(',')}
            />
            <button
              type='button'
              className={classNames(
                formStyles.submitButton,
                formStyles.submitButtonDark,
              )}
              onClick={() => {
                setCookie('reader_topics_modal', 'seen', 7);
                onClose();
              }}
            >
              Maybe later
            </button>
            <button
              disabled={
                isSubmitting || isDataClean || !currectFavoriteTagsIds.length
              }
              className={classNames(
                formStyles.submitButton,
                styles.submitButton,
              )}
              type='submit'
            >
              {isSubmitting ? <Spinner /> : 'Continue'}
            </button>
          </fetcher.Form>
        </div>
      </div>
    </motion.div>
  );
};

export default TopicsModal;
